import { useState, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { withTranslation, WithTranslation } from 'react-i18next';
import { api, apiCore, apiOracle } from '../../api/api';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { AxiosResponse } from 'axios';
import numeral from 'numeral';
import { StyledRegistrationFormBackground } from 'styled-components/StyledRegistrationForm';
import { ReactComponent as MachineIconSvg } from '../../images/frisia/icons/blue-machine-icon.svg';
import { ReactComponent as EditCircleIconSvg } from '../../images/frisia/icons/blue-edit-circle-icon.svg';
import { ReactComponent as TrashCircleIconSvg } from '../../images/frisia/icons/red-trash-circle-icon.svg';
import { ReactComponent as SaveIconSvg } from '../../images/frisia/icons/blue-floppy-icon.svg';
import { ReactComponent as DiscardIconSvg } from '../../images/frisia/icons/blue-discard-changes-icon.svg';
import { ReactComponent as AddIconSvg } from '../../images/frisia/icons/blue-circle-add-icon.svg';

// Definição da interface para os equipamentos
interface IEquipment {
  nr_equipamento: number;
  cd_pessoa: number;
  vl_moeda: number;
  cd_tipo_marca: number; // Alterado para número inteiro para representar o código da marca
  cd_tipo_modelo: number;
  cd_tipo_maquina: number;
  dt_fabricacao: number;
  qt_potencia: string;
  nr_chassi: string;
  ie_segurado: 'N' | 'S';
  ie_alienado: 'N' | 'S';
  dt_vencimento_alienacao: string;
  pc_proprietario: number;
}

interface IMarca {
  cd_tipo_marca: number;
  ds_tipo_marca: string;
}

interface IModelo {
  cd_tipo_modelo: number;
  ds_tipo_modelo: string;
}

interface IMaquina {
  cd_tipo_maquina_eqpto: number;
  ds_tipo_maquina_eqpto: string;
}

const EquipmentList = ({ t }: WithTranslation) => {
  // Estados para os equipamentos, altura do container, índice de edição e equipamento original
  const [equipments, setEquipments] = useState<IEquipment[]>([]);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [originalEquipment, setOriginalEquipment] = useState<IEquipment | null>(null);
  const [marcasDisponiveis, setMarcasDisponiveis] = useState<IMarca[]>([]);
  const [modelosDisponiveis, setModelosDisponiveis] = useState<IModelo[]>([]);
  const [equipamentosDisponiveis, setEquipamentosDisponiveis] = useState<IMaquina[]>([]);
  const [addingNew, setAddingNew] = useState<boolean>(false); // Estado para controlar adição de novo equipamento
  const userRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa ?? 0;
  });
  const formatCurrency = value => {
    if (value === null || value === undefined) {
      return 'R$ 0,00';
    }

    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      useGrouping: true,
    }).format(value);

    return formattedValue;
  };

  const formatPercentage = value => {
    if (value === null || value === undefined) {
      return '0%';
    }

    const formattedValue = `${value}%`;
    return formattedValue;
  };

  useEffect(() => {
    const fetchMarcas = async () => {
      try {
        const response = await apiCore.get<IMarca[]>(`/Cadastro/GetMarcas`);
        setMarcasDisponiveis(response.data);
      } catch (error) {
        console.error('Erro ao buscar marcas:', error);
      }
    };

    fetchMarcas();

    const fetchModelos = async () => {
      try {
        const response = await apiCore.get<IModelo[]>(`/Cadastro/GetModelos`);
        setModelosDisponiveis(response.data);
      } catch (error) {
        console.error('Erro ao buscar modelos:', error);
      }
    };

    fetchModelos();

    const fetchEquipamentos = async () => {
      try {
        const response = await apiCore.get<IMaquina[]>(`/Cadastro/GetMaquinasEquipamentos`);
        setEquipamentosDisponiveis(response.data);
      } catch (error) {
        console.error('Erro ao buscar equipamentos:', error);
      }
    };

    fetchEquipamentos();

    const fetchData = async () => {
      try {
        const response: AxiosResponse<IEquipment[]> = await apiCore.get<IEquipment[]>(
          `/Cadastro/GetDadosEquipamentosCooperado?cd_pessoa=${userRegistration}`
        );
        setEquipments(response.data);
      } catch (error) {
        console.error('Erro ao buscar equipamentos:', error);
      }
    };

    fetchData();

    // Ajuste da altura do container com base na janela
    const updateContainerHeight = () => {
      const windowHeight = window.innerHeight;
      const maxHeight = windowHeight * 0.55;
      setContainerHeight(maxHeight);
    };

    updateContainerHeight();
    window.addEventListener('resize', updateContainerHeight);

    return () => {
      window.removeEventListener('resize', updateContainerHeight);
    };
  }, [userRegistration]);

  // Função para lidar com o clique em editar
  const handleEditClick = (index: number) => {
    setEditingIndex(index);
    setOriginalEquipment({ ...equipments[index] });
  };

  // Função para lidar com o clique em deletar
  const handleDeleteClick = (nrEquipamento: number) => {
    const confirmDelete = window.confirm('Deseja realmente excluir esta linha? Esta operação não poderá ser desfeita.');
    if (confirmDelete) {
      deleteEquipment(nrEquipamento);
    }
  };

  // Função para deletar um equipamento
  const deleteEquipment = (nrEquipamento: number) => {
    const updatedEquipments = equipments.filter(equipment => equipment.nr_equipamento !== nrEquipamento);
    setEquipments(updatedEquipments);
  };

  // Função para lidar com o clique em salvar
  const handleSaveClick = (editedEquipment: IEquipment) => {
    const updatedEquipments = [...equipments];
    updatedEquipments[editingIndex as number] = { ...editedEquipment };
    setEquipments(updatedEquipments);
    setEditingIndex(null);
    setOriginalEquipment(null);
  };

  // Função para lidar com o clique em descartar alterações
  const handleDiscardClick = () => {
    if (originalEquipment && editingIndex !== null) {
      const updatedEquipments = [...equipments];
      updatedEquipments[editingIndex] = { ...originalEquipment };
      setEquipments(updatedEquipments);
    } else if (addingNew) {
      // Se estiver adicionando novo, remove o último equipamento adicionado
      setEquipments(prevEquipments => prevEquipments.slice(0, -1));
    }
    setEditingIndex(null);
    setOriginalEquipment(null);
  };

  // No retorno do componente, ajuste a renderização da linha para verificar se o equipamento está na lista atual
  {
    equipments.map((equipment, index) => (
      <StyledTableRow key={index} style={{ display: equipments.includes(equipment) ? 'table-row' : 'none' }}>
        {/* Renderização do conteúdo da linha aqui */}
      </StyledTableRow>
    ));
  }

  // Função para lidar com a mudança de input (texto)
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, field: keyof IEquipment) => {
    if (editingIndex !== null) {
      const updatedEquipments = [...equipments];
      let value: any = e.target.value;

      if (field === 'pc_proprietario') {
        value = Math.min(100, Math.max(0, parseFloat(value) || 0)); // Limita o valor entre 0 e 100
      }

      if (field === 'vl_moeda') {
        value = value.replace(',', '.'); // Substitui vírgula por ponto
      }

      updatedEquipments[editingIndex] = {
        ...updatedEquipments[editingIndex],
        [field]: value,
      };
      setEquipments(updatedEquipments);
    }
  };

  // Função para lidar com a mudança no dropdown (marca)
  const handleMarcaChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (editingIndex !== null) {
      const updatedEquipments = [...equipments];
      updatedEquipments[editingIndex].cd_tipo_marca = parseInt(e.target.value); // Converte para número
      setEquipments(updatedEquipments);
    }
  };

  // Função para lidar com a mudança no dropdown (modelo)
  const handleModeloChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (editingIndex !== null) {
      const updatedEquipments = [...equipments];
      updatedEquipments[editingIndex].cd_tipo_modelo = parseInt(e.target.value); // Converte para número
      setEquipments(updatedEquipments);
    }
  };

  // Função para lidar com a mudança no dropdown (maquina)
  const handleMaquinaChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (editingIndex !== null) {
      const updatedEquipments = [...equipments];
      updatedEquipments[editingIndex].cd_tipo_maquina = parseInt(e.target.value); // Converte para número
      setEquipments(updatedEquipments);
    }
  };

  // Função para lidar com a mudança no dropdown (booleano)
  const handleDropdownChange = (value: boolean, field: keyof IEquipment) => {
    if (editingIndex !== null) {
      const updatedEquipments = [...equipments];
      // Utilize o type assertion para informar ao TypeScript que field é uma chave válida de IEquipment
      updatedEquipments[editingIndex] = {
        ...updatedEquipments[editingIndex],
        [field]: value ? '1' : '0',
      };
      setEquipments(updatedEquipments);
    }
  };

  // Função para lidar com o clique em adicionar novo equipamento
  const handleAddClick = () => {
    setAddingNew(true);
    // Lógica para adicionar um novo equipamento ao estado de equipamentos
    const newEquipment: IEquipment = {
      nr_equipamento: 0,
      cd_pessoa: userRegistration,
      vl_moeda: 0,
      cd_tipo_marca: 0, // Exemplo de marca inicial
      cd_tipo_modelo: 0,
      cd_tipo_maquina: 0,
      dt_fabricacao: 0,
      qt_potencia: '',
      nr_chassi: '',
      ie_segurado: 'N',
      ie_alienado: 'N',
      dt_vencimento_alienacao: '',
      pc_proprietario: 100,
    };
    setEquipments([...equipments, newEquipment]);
    setEditingIndex(equipments.length); // Foca na última linha para edição
    setOriginalEquipment(null);
  };

  return (
    <StyledRegistrationFormBackground>
      <StyledHeaderForm>
        <StyledTitleContainer>
          <MachineIconSvg />
          <span>{t('registrationForm.machineData')}</span>
        </StyledTitleContainer>
        {/* Ícone de adicionar somente é exibido quando o editingIndex for null */}
        {editingIndex === null && (
          <IconWrapper title="Adicionar novo equipamento" onClick={handleAddClick}>
            <AddIconSvg />
            <span>{t('registrationForm.addMachine')}</span>
          </IconWrapper>
        )}
      </StyledHeaderForm>
      <StyledContainer style={{ maxHeight: `${containerHeight}px` }}>
        <StyledTable>
          <thead>
            <StyledTableHeaderRow>
              <StyledTableHeaderCell style={{ width: '120px' }}>AÇÕES</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ width: '110px' }}>CÓDIGO</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ width: '500px' }}>EQUIPAMENTO</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ width: '500px' }}>MARCA</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ width: '500px' }}>MODELO</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ width: '150px' }}>POTÊNCIA</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ width: '130px' }}>ANO FABR.</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ width: '160px' }}>VALOR</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ width: '360px' }}>CHASSI</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ width: '130px' }}>SEGURADO</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ width: '130px' }}>ALIENADO</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ width: '190px' }}>VENC. ALIENAÇÃO</StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ width: '180px' }}>% PROPRIETÁRIO</StyledTableHeaderCell>
            </StyledTableHeaderRow>
          </thead>
          <tbody>
            {equipments.map((equipment, index) => (
              <StyledTableRow key={index} style={{ display: editingIndex !== null && editingIndex !== index ? 'none' : 'table-row' }}>
                <StyledTableIconCell>
                  {editingIndex === null && (
                    <IconWrapper title="Editar linha" onClick={() => handleEditClick(index)}>
                      <EditCircleIconSvg />
                    </IconWrapper>
                  )}

                  {/* Ícone de salvar */}
                  {editingIndex === index && (
                    <IconWrapper title="Salvar alterações" onClick={() => handleSaveClick(equipment)}>
                      <SaveIconSvg />
                    </IconWrapper>
                  )}

                  {/* Ícone de descartar */}
                  {editingIndex === index && (
                    <IconWrapper title="Descartar alterações" onClick={handleDiscardClick}>
                      <DiscardIconSvg />
                    </IconWrapper>
                  )}

                  {/* Ícone de excluir */}
                  {editingIndex === null && (
                    <IconWrapper title="Excluir linha" onClick={() => handleDeleteClick(equipment.nr_equipamento)}>
                      <TrashCircleIconSvg />
                    </IconWrapper>
                  )}
                </StyledTableIconCell>
                <StyledTableCell>{equipment.nr_equipamento}</StyledTableCell>
                <StyledTableCell>
                  {editingIndex === index ? (
                    <select value={equipment.cd_tipo_maquina.toString()} onChange={handleMaquinaChange}>
                      {equipamentosDisponiveis.map(maquina => (
                        <option key={maquina.cd_tipo_maquina_eqpto} value={maquina.cd_tipo_maquina_eqpto.toString()}>
                          {maquina.ds_tipo_maquina_eqpto}
                        </option>
                      ))}
                    </select>
                  ) : (
                    equipamentosDisponiveis.find(maquina => maquina.cd_tipo_maquina_eqpto === equipment.cd_tipo_maquina)
                      ?.ds_tipo_maquina_eqpto
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {editingIndex === index ? (
                    <select value={equipment.cd_tipo_marca.toString()} onChange={handleMarcaChange}>
                      {marcasDisponiveis.map(marca => (
                        <option key={marca.cd_tipo_marca} value={marca.cd_tipo_marca.toString()}>
                          {marca.ds_tipo_marca}
                        </option>
                      ))}
                    </select>
                  ) : (
                    marcasDisponiveis.find(marca => marca.cd_tipo_marca === equipment.cd_tipo_marca)?.ds_tipo_marca
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {editingIndex === index ? (
                    <select value={equipment.cd_tipo_modelo.toString()} onChange={handleModeloChange}>
                      {modelosDisponiveis.map(modelo => (
                        <option key={modelo.cd_tipo_modelo} value={modelo.cd_tipo_modelo.toString()}>
                          {modelo.ds_tipo_modelo}
                        </option>
                      ))}
                    </select>
                  ) : (
                    modelosDisponiveis.find(modelo => modelo.cd_tipo_modelo === equipment.cd_tipo_modelo)?.ds_tipo_modelo
                  )}
                </StyledTableCell>
                <StyledEditableTableCell editing={editingIndex === index}>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      value={equipment.qt_potencia}
                      onChange={e => handleInputChange(e, 'qt_potencia')}
                      placeholder="Digite aqui"
                    />
                  ) : (
                    equipment.qt_potencia
                  )}
                </StyledEditableTableCell>
                <StyledEditableTableCell editing={editingIndex === index}>
                  {editingIndex === index ? (
                    <input
                      type="number"
                      value={equipment.dt_fabricacao}
                      onChange={e => handleInputChange(e, 'dt_fabricacao')}
                      placeholder="Digite aqui"
                    />
                  ) : (
                    equipment.dt_fabricacao
                  )}
                </StyledEditableTableCell>
                <StyledEditableTableCell editing={editingIndex === index}>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      value={equipment.vl_moeda}
                      onChange={e => handleInputChange(e, 'vl_moeda')}
                      placeholder="Digite aqui"
                    />
                  ) : (
                    formatCurrency(equipment.vl_moeda)
                  )}
                </StyledEditableTableCell>
                <StyledEditableTableCell editing={editingIndex === index}>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      value={equipment.nr_chassi}
                      onChange={e => handleInputChange(e, 'nr_chassi')}
                      placeholder="Digite aqui"
                    />
                  ) : (
                    equipment.nr_chassi
                  )}
                </StyledEditableTableCell>
                <StyledEditableTableCell editing={editingIndex === index}>
                  {editingIndex === index ? (
                    <select value={equipment.ie_segurado} onChange={e => handleDropdownChange(e.target.value === '1', 'ie_segurado')}>
                      <option value="S">SIM</option>
                      <option value="N">NÃO</option>
                    </select>
                  ) : equipment.ie_segurado === 'S' ? (
                    'SIM'
                  ) : (
                    'NÃO'
                  )}
                </StyledEditableTableCell>
                <StyledEditableTableCell editing={editingIndex === index}>
                  {editingIndex === index ? (
                    <select value={equipment.ie_alienado} onChange={e => handleDropdownChange(e.target.value === '1', 'ie_alienado')}>
                      <option value="S">SIM</option>
                      <option value="N">NÃO</option>
                    </select>
                  ) : equipment.ie_alienado === 'S' ? (
                    'SIM'
                  ) : (
                    'NÃO'
                  )}
                </StyledEditableTableCell>
                <StyledEditableTableCell editing={editingIndex === index}>
                  {editingIndex === index ? (
                    <InputMask
                      mask="99/99/9999"
                      value={equipment.dt_vencimento_alienacao}
                      onChange={e => handleInputChange(e, 'dt_vencimento_alienacao')}
                    >
                      {(inputProps: any) => <input {...inputProps} placeholder="DD/MM/AAAA" />}
                    </InputMask>
                  ) : (
                    equipment.dt_vencimento_alienacao
                  )}
                </StyledEditableTableCell>
                <StyledEditableTableCell editing={editingIndex === index}>
                  {editingIndex === index ? (
                    <input
                      type="number"
                      value={equipment.pc_proprietario}
                      onChange={e => handleInputChange(e, 'pc_proprietario')}
                      min="0"
                      max="100"
                      placeholder="Digite aqui"
                    />
                  ) : (
                    formatPercentage(equipment.pc_proprietario)
                  )}
                </StyledEditableTableCell>
              </StyledTableRow>
            ))}
          </tbody>
        </StyledTable>
      </StyledContainer>
    </StyledRegistrationFormBackground>
  );
};

const StyledHeaderForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 16px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  span {
    margin-bottom: -1px;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  border: 1px solid #ddd;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

const StyledTableHeaderRow = styled.tr`
  background: #f1f1f1;
`;

const StyledTableHeaderCell = styled.th`
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  position: sticky;
  top: 0;
  background-color: #f1f1f1; /* Cor de fundo para o cabeçalho fixo */
  z-index: 1;
`;

const StyledTableRow = styled.tr`
  &:nth-child(even) {
    background: #f9f9f9;
  }
`;

const StyledTableCell = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
`;

const StyledTableIconCell = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;

const StyledEditableTableCell = styled(StyledTableCell)<{ editing: boolean }>`
  position: relative;
  padding: 8px;
  border: 1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;

  input,
  select {
    width: 100%;
    border: none;
    background: ${({ editing }) => (editing ? '#f0f8ff' : 'transparent')}; /* Cor de fundo para indicar edição */
    font-family: inherit;
    font-size: inherit;
    padding: 4px 8px;
    box-sizing: border-box;
  }
`;

const IconWrapper = styled.div`
  display: inline-block; /* Torna os ícones em linha */
  cursor: pointer;
  margin-right: 5px;

  svg {
    width: 40px; /* Ajusta o tamanho dos ícones */
    height: 40px;
    fill: #007bff;
  }

  &:hover {
    svg {
      fill: #0056b3;
    }
  }
`;

export default withTranslation()(EquipmentList);
